/*Styling should be done in react, only use css for css animation or other features of css that cannot be done using styles*/
/*We want the components to have everything to be rendered*/
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
html, body {margin: 0; height: 100%; overflow: hidden}

#react-app, #app-container {
}
*, *:focus, *:hover {
    outline: none !important;
}

#react-app {
    height: 100%;
}

.no-border-radius-right .MuiInputBase-root {
    -ms-border-bottom-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    -ms-border-top-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.MuiExpansionPanel-root:before {
    height: 0px !important;
    background-color: transparent;
}

.location-items .MuiAutocomplete-option {
    color: #6F6F6E;
}
.location-items .MuiAutocomplete-option:hover {
    color: #00AFD5;
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.navlink {
    color: #6F6F6E;
}

.navlink:hover {
    color: #00AFD5;
}
.navlink.active {
    color: #00AFD5;
}

.documentHover:hover {
    color: #00AFD5;
}

.tabs {
    width: 400px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tab-links {
    margin: 0;
    padding: 0;
    border: 1px solid #CCC;
    border-bottom: 1px solid #868686;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tab-link {
    padding: 0 15px;
    cursor: pointer;
    border: 1px solid transparent;
    display: inline-block;
}

.tab-link-active {
    font-weight: bold;
    border: 1px solid #868686;
    border-bottom: 1px solid white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
}

.hover-blue:hover {
   color: #00AFD5;
}
.bghover-blue:hover {
    background-color: #c4ecf5;
}
.bglightgray {
    background-color: #f0f0f0;
}

.control-label {
    text-align: right;
}
.OkSelect-root:hover {
    font-weight: bold !important;
}

.MuiSelect-icon:hover {
    color: #00AFD5 !important;
}

.MuiPickersDay-root {
    -ms-border-radius: 8px !important;
    border-radius: 8px !important;
}

.port-search-result {
    background-color: white;
}

.port-search-result-alt {
    background-color: #f0f0f0;
}

.port-search-result:hover {
    background-color: #c4ecf5;
}
.port-search-result-alt:hover {
    background-color: #c4ecf5;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: white !important;
}

.MuiTooltip-tooltip {
    background-color: white !important;
    color: #6F6F6E !important;
    border: 1px solid #6F6F6E !important;
    font-size: 0.8rem !important;
    max-width: 600px !important;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.MuiAutocomplete-inputRoot {
    padding: 4px !important;
}